@use '../styles/common'

.Article
	margin: common.$spacing*8 0 common.$spacing*10
	position: relative
	border-radius: 6px
	border: 1px solid common.$goodlokLightHover

	&:first-child
		margin-top: common.$spacing*11

	&:last-child
		margin-bottom: common.$spacing*7

	@media (min-width: common.$breakMobile)
		margin: common.$spacing*11 0 common.$spacing*13

.ArticleSupplier
	margin: common.$spacing*11 0 common.$spacing*19
	@media (min-width: common.$breakMobile)
		margin: common.$spacing*13 0 common.$spacing*21

.ArticleSupplierMargin
	margin-bottom: common.$spacing*12
	@media (min-width: common.$breakMobile)
		margin-bottom: common.$spacing*15

.ButtonWrapper
	position: absolute
	top: calc(100% + 10px)
	right: 0
	&::before
		content: ''
		width: calc(100% + 10px)
		height: 68px
		border-radius: 12px
		background: common.$goodlokLightOpacity
		position: absolute
		top: -28px
		left: -9px
		z-index: 0

	@media (min-width: common.$breakMobile)
		top: calc(100% + 12px)
		&::before
			width: calc(100% + 10px)
			height: 72px

.Button
	margin-right: common.$spacing*2
	padding: common.$spacing common.$spacing*2
	border-radius: 12px
	position: relative
	background: common.$goodlokLight
	z-index: 1
	border: 1px solid
	font-size: 0.9em

	&:hover
		background: common.$goodlokDark
		color: common.$goodlokLight

	@media (min-width: common.$breakMobile)
		font-size: 1.1em
