@use '../styles/common'

.ItemWrapper
	padding: 2px 0
	display: grid
	gap: 0.4em
	align-items: center
	grid-template-columns: 2fr 1.5fr 1.5fr 1fr
	background: common.$goodlokLight
	z-index: 2
	position: relative
	font-size: 1.1em

	&:first-of-type:not(:only-child)
		border-radius: 12px 12px 0 0

	&:last-of-type:not(:only-child)
		border-radius: 0 0 6px 6px

	&:first-of-type:only-of-type
		border-radius: 12px

	td
		padding: 2px common.$spacing*2

	&:not(:last-of-type)
		border-bottom: 1px solid common.$goodlokLightHover

	@media (min-width: common.$breakMobile)

		grid-template-columns: 1.5fr 1fr 1fr 1fr
		gap: 1.3em
		font-size: 1.2em
		td
			padding: 3px common.$spacing*2 3px common.$padding

.ItemWrapperReorder
	grid-template-columns: 1fr 0.7fr 0.9fr 0.8fr

	td
		padding: 2px common.$spacing

	@media (min-width: common.$breakMobile)
		grid-template-columns: 2fr 1.5fr 1.5fr 2fr
		td
			padding: 3px common.$spacing*2 3px common.$padding

.ItemAttrs
	display: flex

.BtnGroup
	justify-content: flex-end
	display: flex
	z-index: 2
	align-items: center

.Title
	&::first-letter
		text-transform: capitalize

.Btn
	padding: 6px
	margin: 2px common.$spacing
	border-radius: 100%
	display: inline-flex
	align-items: center
	justify-content: center

	&:last-child
		margin-right: 0

	@media (min-width: common.$breakMobile)
		padding: common.$spacing*2
		margin: 2px common.$spacing*2

.ConfirmBtn
	width: 30px
	height: 30px
	border: 1px solid common.$goodlokDark
	background: common.$goodlokLight
	svg
		fill: common.$goodlokDark
		width: 16px
		height: 15px

	&:hover
		background: common.$goodlokDark
		svg
			fill: common.$goodlokLight

	@media (min-width: common.$breakMobile)
		width: 36px
		height: 36px
		svg
			width: 30px
			height: 19px

.RemoveBtn
	svg
		stroke: common.$goodlokDark
		width: 12px
		height: 12px
		@media (min-width: common.$breakMobile)
			width: 15px
			height: 15px

.ItemSupplier
	display: flex
	align-items: center

.AlertItemDue
	color: common.$goodlokRed

.Logo
	object-fit: contain

.Amount,
.ItemDue
	font-size: 0.9em

.LogoWrapper
	display: flex
	align-items: center

.Contact
	font-weight: 400
	font-size: 0.9em
	margin-left: common.$spacing*2
	display: block

	@media (min-width: common.$breakMobile)
		font-size: 0.8em

.Extra
	display: none
	position: absolute
	text-transform: uppercase
	top: -24px
	font-size: 0.9em
	left: 0
	font-weight: 600
	&:first-of-type
		display: flex
	@media (min-width: common.$breakMobile)
		font-size: 1.1em
		top: -30px

.ExtraLogo
	top: -44px

.DoubleCheckBtn
	padding: common.$spacing 6px
	border: 1px solid
	border-radius: 12px
	color: common.$goodlokLight
	background: common.$goodlokDark

.ReorderBtn
	padding: common.$spacing
	border: 1px solid
	border-radius: 12px
	color: common.$goodlokDark
	background: common.$goodlokLight
	width: 100%
	&:hover
		background: common.$goodlokDark
		color: common.$goodlokLight
	@media (min-width: common.$breakMobile)
		margin: 0 common.$spacing*2
		width: auto
	@media (min-width: common.$breakDesktop)
		margin: 0 common.$spacing*5

.Form
	background: common.$goodlokLightOpacity
	padding: common.$spacing common.$spacing*2
	border-radius: 7px
	min-width: 100px

	select
		outline: none
		width: 100%
		font-weight: 400
		font-family: inherit
		font-size: 0.8em
		padding: common.$spacing
		border: 1px solid common.$goodlokLightHover
		border-radius: 4px
		&::after
			top: 0
	button
		margin: 0
		margin-top: common.$spacing

	@media (min-width: common.$breakMobile)
		display: flex
		max-width: unset
		button
			margin: 0 common.$spacing*2

.Select
	appearance: none
	background: common.$white
