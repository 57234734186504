@use '../styles/common'

.RowWrapper
    background: common.$goodlokLightOpacity

.Row
    font-size: 1.1em
    padding: common.$spacing*3 common.$spacing*3 common.$spacing*3 common.$spacing*2
    max-width: common.$containerWidth
    display: flex
    align-items: center
    margin-left: auto
    margin-right: auto
    flex-wrap: wrap
    @media (min-width: common.$breakMobile)
        padding: common.$spacing*5
        font-size: 1.2em

.Button
    position: relative
    font-size: 0.9em
    margin: common.$spacing
    padding: common.$spacing common.$spacing*2
    border-radius: 12px
    transition: 0.2s background ease-in-out
    border: 1px solid
    &:hover
        background: common.$goodlokLightHover

    @media (min-width: common.$breakMobile)
        margin: 0 common.$spacing
        &:first-of-type
            margin-left: common.$spacing*2

.ActiveButton
    background: common.$goodlokDark
    color: common.$goodlokLight
    &:hover
        background: common.$goodlokDarkHover
.MobileHidden
    display: none
    @media (min-width: common.$breakMobile)
        display: block
