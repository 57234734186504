@use '../styles/common'

.Button
    position: relative
    font-size: 1.2em
    color: common.$goodlokDark
    transition: opacity ease-in-out .2s
    border: 1px solid
    padding: common.$spacing*2 common.$spacing*4
    border-radius: 20px
    margin: common.$spacing*4 0

    &:hover
        background: common.$goodlokDark
        color: common.$goodlokLight

    @media (min-width: common.$breakDesktop)
        font-size: 1.4em
        border: 0

        span
            display: inline-block
            &::after
                width: 100%
                transition: 0.2s transform ease-out
                height: 1px
                content: ''
                display: block
                margin-top: 4px
                background: common.$goodlokDark

        &:hover
            background: transparent
            color: common.$goodlokDark
            span
                &::after
                    transform: translateY(2px)

.FormWrapper
    background: common.$goodlokLightHover
    position: relative

.Form
    max-width: common.$containerWidth
    margin-left: auto
    margin-right: auto
    width: 100%
    display: flex
    flex-wrap: wrap
    flex-direction: column
    align-items: center
    padding: common.$padding common.$spacing*3 0
    input,
    select,
    option
        outline: none
        width: 100%
        font-weight: 400
        font-family: inherit
        font-size: 1.2em
        padding: common.$spacing*2
        border: 1px solid common.$goodlokLightHover
        border-radius: 4px

    .ButtonWrapper
        margin-right: auto

    @media (min-width: common.$breakMobile)
        flex-direction: row
        input,
        option,
        .SelectWrapper
            width: 25%

        .ButtonWrapper
            width: 100%
            margin-right: 0
            text-align: center

    @media (min-width: common.$breakDesktop)
        padding: common.$padding*2 common.$padding
        display: grid
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr
        font-size: 0.8em
        input,
        select,
        option
            width: 100%
            max-width: 188px
            font-size: 1.4em
        .SelectWrapper
            width: 100%

.Select
    appearance: none
    background: common.$white

.SelectWrapper
    position: relative
    width: 100%
    &::after
        content: ''
        position: absolute
        top: 17px
        right: common.$spacing*4
        box-sizing: border-box
        height: 8px
        width: 8px
        border-style: solid
        border-width: 0px 1px 1px 0px
        transform: rotate(45deg)
        transition: border-width 150ms ease-in-out

        @media (min-width: common.$breakMobile)
            right: common.$spacing*3

        @media (min-width: common.$breakDesktop)
            top: 14px

.SelectWrapperReorder
    &::after
        top: calc(50% - 4px)
        width: 6px
        height: 6px

.HideButtonWrapper
    position: absolute
    bottom: common.$spacing*6
    right: common.$padding
    border: 1px solid
    border-radius: 100%
    background: common.$goodlokDark
    width: 28px
    height: 28px
    display: flex
    align-items: center
    justify-content: center

.HideButtonWrapperCollapsed
    border: 1px solid
    background: common.$goodlokDark
    width: 28px
    height: 28px
    border-radius: 100%
    display: flex
    align-items: center
    justify-content: center
    bottom: calc(50% - 14px)
    margin-left: common.$spacing*2

.Arrow
    display: block
    box-sizing: border-box
    height: 8px
    width: 8px
    border-style: solid
    margin-top: 2px
    border-color: common.$goodlokLight
    border-width: 0px 1px 1px 0px
    transform: rotate(225deg)
    transition: border-width 150ms ease-in-out

.HideDesktop
    display: flex
    @media (min-width: common.$breakMobile)
        display: none

.ArrowDown
    transform: rotate(45deg)
    margin-bottom: 2px
    margin-top: 0

.HideButtonCollapsed
    font-size: 1.2em
    display: flex
    width: 100%
    justify-content: flex-end
    span
        &:after
            transition: 0.2s transform ease-out

    &:hover
        span
            &::after
                transform: translateY(2px)

.HideButtonText
    display: inline-block
    &::after
        width: 100%
        height: 1px
        content: ''
        display: block
        margin-top: 4px
        background: common.$goodlokDark
