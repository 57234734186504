//@use 'common'
@use 'sass:math'
@use '../styles/common'

.Header
	text-transform: uppercase
	display: flex
	align-items: center
	padding: common.$spacing*3 common.$padding
	background: common.$goodlokDark

	&.overlay
		position: absolute
		top: 0
		left: 0
		right: 0
		z-index: 1

.Container
	@media (max-width: common.$breakMobile - .1px)
		.open &
			background: common.$goodlokDark
			color: common.$goodlokLight
			min-height: 100vh

		@media (min-width: common.$breakMobile)
			padding: common.$padding

.Title
	font-size: 1.6em
	color: common.$goodlokLight

	@media (min-width: common.$breakMobile)
		font-size: 1.8em

.LogoTitle
	display: flex
	align-items: center

.Cell
	width: calc(50% - 64px)
	flex-grow: 1

	&.logo
		flex-grow: 0
		margin-top: common.$spacing

		a
			display: inline-block
			-webkit-tap-highlight-color: transparent
			outline: none

	&:last-child
		@media (max-width: 330px)
			display: none

.onlySmall
	svg
		height: 36px
		width: 17px
		stroke: common.$goodlokLight
		fill: common.$goodlokLight

	@media (min-width: common.$breakMobile)
		display: none

.onlyLarge
	svg
		width: 140px
		height: 36px
		stroke: common.$goodlokLight
		fill: common.$goodlokLight

	@media (max-width: common.$breakMobile - .1px)
		display: none

.G
	font-size: math.div(53em, 16)
	height: 1em
	width: 1em * math.div(25, 54)

.GoodlokText
	font-size: math.div(27em, 16)
	height: 1em
	width: 1em * math.div(1096, 290)
