@use '../styles/common'

.Table
	width: 100%

.Header
	width: 100%
	display: grid
	grid-template-columns: 1.4fr 1fr 1fr 1.1fr
	gap: 0.4em
	font-size: 0.8em
	margin-bottom: common.$spacing*2
	opacity: 0.8
	@media (min-width: common.$breakMobile)
		margin-bottom: 0
		grid-template-columns: 1.7fr 1.1fr 1fr 1.1fr
		gap: 1em
		font-size: 1em

.HeaderCell
	&:last-child
		text-align: right

.Container
	max-width: common.$containerWidth
	margin: common.$spacing*3 auto common.$spacing*5
	padding: 0 common.$spacing*3
	@media (min-width: common.$breakMobile)
		padding: 0 common.$padding common.$padding

.Button
	position: relative
	font-size: 1.1em
	&::after
		content: ''
		position: absolute
		top: calc(50% - 3px)
		right: -12px
		box-sizing: border-box
		height: 7px
		width: 7px
		border-style: solid
		border-width: 0px 1px 1px 0px
		transform: rotate(315deg)
		transition: border-width 150ms ease-in-out
		transition: 0.2s ease-in transform
	&:hover
		&::after
			transform: translate(4px) rotate(315deg)
